import CdrRecipient from "@/domain/cdrRecipient";
import CdrSubscription from "@/domain/cdrSubscription";
import Consent from "@/domain/consent";
export default class ApplicationStore {
  public debug = false;
  public accessToken = "";
  public partyKey = "";
  public authCallbackURI = "";

  public consentRequest?: Consent | null = null;
  public authorisationPromise?: Promise<boolean>;
  public dataRecipient?: CdrRecipient | null = null;

  public selectedAccounts: string[] = [];
  public allAccounts: CdrSubscription[] = [];

  public setDebug() {
    this.debug = true;
    console.log(
      `ApplicationStore: running in DEBUG`
    );
  }

  public updatePartyKey(newPartyKey: string) {
    if (this.debug) {
      console.log(
        `Updating partyKey: old ${this.partyKey}, new ${newPartyKey}`
      );
    }

    this.partyKey = newPartyKey;
  }

  public updateAccessToken(newAccessToken: string) {
    if (this.debug) {
      console.log(
        `Updating accessToken: old ${this.accessToken}, new ${newAccessToken}`
      );
    }

    this.accessToken = newAccessToken;
  }

  public updateAuthorisationPromise(newAuthorisationPromise: Promise<boolean>) {
    if (this.debug) {
      console.log(
        `Updating authorisationPromise: old ${this.authorisationPromise}, new ${newAuthorisationPromise}`
      );
    }

    this.authorisationPromise = newAuthorisationPromise;
  }

  public updateDataRecipient(newDataRecipient: CdrRecipient) {
    if (this.debug) {
      console.log(
        `Updating dataRecipient: old ${this.dataRecipient}, new ${newDataRecipient}`
      );
    }

    this.dataRecipient = newDataRecipient;
  }

  public updateConsentRequest(newConsentRequest: Consent) {
    if (this.debug) {
      console.log(
        `Updating consentRequest: old ${this.consentRequest}, new ${newConsentRequest}`
      );
    }

    this.consentRequest = newConsentRequest;
  }

  public updateAuthCallbackURI(newAuthCallbackURI: string) {
    if (this.debug) {
      console.log(
        `Updating authCallbackURI: old ${this.authCallbackURI}, new ${newAuthCallbackURI}`
      );
    }

    this.authCallbackURI = newAuthCallbackURI;
  }

  public updateAllAccounts(newAllAccounts: CdrSubscription[]) {
    if (this.debug) {
      console.log(
        `Updating allAccounts: old ${this.allAccounts}, new ${newAllAccounts}`
      );
    }

    this.allAccounts = newAllAccounts;
  }

  public updateSelectedAccounts(newSelectedAccounts: string[]) {
    if (this.debug) {
      console.log(
        `Updating selectedAccounts: old ${this.selectedAccounts}, new ${newSelectedAccounts}`
      );
    }

    this.selectedAccounts = newSelectedAccounts;
  }
}
