
import ApplicationStore from "@/stores/applicationStore";
import { defineComponent, inject } from "vue";

export default defineComponent({
  setup() {
    const applicationStore = inject("applicationStore") as ApplicationStore;

    return {
      DH: {
        name: "SocietyOne",
        acn: "609 495 409",
      },
      ADR: applicationStore.dataRecipient,
      CDR_POLICY_LINK: process.env.VUE_APP_CDR_POLICY_LINK
    }
  }
});
