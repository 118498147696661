
import { defineComponent, PropType } from "vue";
import PanelGroup from "./PanelGroup.vue";
import CdrSubscription from "@/domain/cdrSubscription";

export default defineComponent({
  data() {
    return {
      sa: this.selected,
    };
  },
  props: {
    selectable: Boolean,
    accounts: {
      type: Array as PropType<CdrSubscription[]>,
      default: () => []
    },
    selected: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  components: {
    PanelGroup
  },
  methods: {
    toggle(subscriptionId: string) {
      if (!this.selectable) return;

      if (this.sa.includes(subscriptionId)) {
        this.sa.splice(
          this.sa.indexOf(subscriptionId),
          1
        );
      } else {
        this.sa.push(subscriptionId);
      }

      this.$emit("change", this.sa);
    },
    selectAll() {
      this.sa = this.accounts.map(
        (account: any) => {
          return account.subscriptionKey;
        }
      );

      this.$emit("change", this.sa);
    },
  },
});
