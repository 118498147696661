
import { defineComponent, inject, onMounted, ref } from "vue";
import ListAccounts from "../components/ListAccounts.vue";
import ApplicationStore from "@/stores/applicationStore";
import AccountService from "@/services/accountService";
import useNavigation from "@/helpers/useNavigation";

export default defineComponent({
  components: {
    ListAccounts,
  },
  data() {
    return {
      errorMessage: "",
    };
  },
  props: {
    token: { type: String },
    partyKey: { type: String },
    consentId: { type: String },
  },
  setup() {
    const applicationStore = inject("applicationStore") as ApplicationStore;
    const accountService = inject("accountService") as AccountService;

    const navigationHandler = useNavigation();

    // If there is an existing arrangement then we are ammending an existing consent, so prepop the current accounts
    if (
      applicationStore.consentRequest?.existingArrangement?.attachedAccounts
    ) {
      console.log(
        `Attached accounts: ${applicationStore.consentRequest?.existingArrangement?.attachedAccounts}`
      );
      applicationStore.updateSelectedAccounts(
        applicationStore.consentRequest?.existingArrangement?.attachedAccounts
      );
    }

    onMounted(async () => {
      try {
        const accountListResponse = await accountService.listAccounts(
          applicationStore.accessToken
        );

        const subscriptions = accountListResponse.subscriptions;

        applicationStore.updateAllAccounts(subscriptions);
      } catch (e) {
        navigationHandler.error("There was a problem retrieving accounts");
        return;
      }
    });

    return {
      navigationHandler,
      applicationStore: ref(applicationStore),
    };
  },
  methods: {
    updateSelectedAccounts(accounts: string[]) {
      this.applicationStore.updateSelectedAccounts(accounts);
    },
    submit() {
      this.$data.errorMessage = "";

      if (this.applicationStore.selectedAccounts.length === 0) {
        this.$data.errorMessage = "Please select at least one account";
        return;
      }

      this.navigationHandler.navigateTo("confirmation");
    },
    cancel() {
      this.navigationHandler.cancel();
    },
  },
});
