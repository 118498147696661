
import { inject, defineComponent, onMounted, ref } from "vue";
import AuthenticationService from "@/services/authenticationService";
import ClientService from "@/services/clientService";
import { useRoute } from "vue-router";
import InitOtpResponse from "@/domain/initOtpResponse";
import ApplicationStore from "@/stores/applicationStore";
import Footer from "@/components/Footer.vue";
import LoginResponse from "@/domain/loginResponse";
import ConsentService from "@/services/consentService";
import useNavigation from "@/helpers/useNavigation";

export default defineComponent({
  components: { Footer },
  props: {
    consentId: { type: String, required: true }
  },
  data() {
    return {
      timer: 0,
      username: "",
      otp: "",
      step: "login",
      loginResponse: {},
      timeRemaining: 0,
      PolicyURL: "",
      DH: {
        name: "SocietyOne",
        acn: "609 495 409",
      },
      OTP: {
        expiry: new Date(new Date().getTime() + 10 * 60000),
        phone: "**** *** 746",
      },
      error: "",
    };
  },
  methods: {
    validateEmail(email: string): boolean {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    cancel() {
      this.navigationHandler.cancel();
    },
    async initOTP() {
      if (!this.validateEmail(this.username)) {
        this.error = "Please enter a valid email";
        return;
      } else {
        this.error = "";
      }
      
      var initResponse: InitOtpResponse;

      try {
        initResponse = await this.authenticationService.initOTP(
          this.username,
          this.$props.consentId
        );
      } catch (e) {
        this.navigationHandler.error("Error initialising OTP");
        return;
      }

      this.$data.loginResponse = initResponse;
      this.$data.step = "OTP";
    },
    async redirect() {
      if (!this.$data.otp) {
        this.error = "Please enter the one time password";
        return;
      } else {
        this.error = "";
      }

      var loginResponse: LoginResponse;

      // Login using the OTP
      try {
        loginResponse = await this.authenticationService.loginOTP(
          this.$data.username,
          (this.$data.loginResponse as InitOtpResponse).authId,
          this.$props.consentId,
          this.$data.otp
        );

        if (process.env.VUE_APP_PARTY_KEY) {
          this.applicationStore.updatePartyKey(process.env.VUE_APP_PARTY_KEY);
        }

        this.applicationStore.updateAccessToken(loginResponse.accessToken);
      } catch(e: any) {
        if (e.response.data.message === "Invalid authorisation") {
          this.error = "Incorrect one time password";
        }
        else {
          this.navigationHandler.error("Error logging in using OTP");
        }

        return;
      }

      // Retrieve the consent request to verify that the token works
      try {
        const consentRequest = await this.consentService.retriveConsent(
          this.applicationStore.accessToken,
          this.applicationStore.partyKey,
          this.$props.consentId as string 
        );

        this.applicationStore.updateConsentRequest(consentRequest);
      } catch(e: any) {
        this.navigationHandler.error("Error retrieving consent request");
        return;
      }

      this.navigationHandler.navigateTo("select-accounts");
    }
  },
  setup(props) {
    const route = useRoute();

    const authenticationService = inject("authenticationService") as AuthenticationService;
    const consentService = inject("consentService") as ConsentService;
    const clientService = inject("clientService") as ClientService;
    const applicationStore = inject("applicationStore") as ApplicationStore;
    const navigationHandler = useNavigation();

    applicationStore.updateAuthCallbackURI(
      route.query.auth_callback_uri as string
    );

    onMounted(async () => {
      try {
        const recipient = await clientService.retriveClient(
          route.query.client_id as string
        );
        applicationStore.updateDataRecipient(recipient);
        
      } catch (e) {
        navigationHandler.error("Error retrieving client information");
      }
    });

    console.log(props);

    return {
      authenticationService,
      navigationHandler,
      consentService,
      applicationStore: ref(applicationStore),
      CONTACT: process.env.VUE_APP_CONTACT,
      CDR_POLICY_LINK: process.env.VUE_APP_CDR_POLICY_LINK
    };
  },
});
